.dropzone {
  border: 2px dashed #cccccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.dropzone.active {
  border: 2px solid #2196f3;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2196f3; /* Set the color of the spinner */
}

.loading-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ddd; /* Loading bar background color */
}

.loading-bar {
  height: 100%;
  background-color: #3498db; /* Loading bar color */
  transition: width 0.3s ease-in-out;
}

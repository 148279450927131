.amplify-button--primary {
    height: 51px;
    border-radius: 8px;
    border: 0;
    background-color: #2173FF;

    font-size: 1em;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    font-style: normal;
    line-height: normal;
}

.amplify-input {
    height: 48px;
    font-size: 1em;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: rgba(186, 186, 186, 0.5);
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid #BABABA;

    &::placeholder {
        color: rgba(186, 186, 186, 0.5);
        font-family: "Inter", sans-serif;
        font-weight: 400;
    }
}

.amplify-tabs {
    display: none;
}

.amplify-button--link {
    font-size: 1em;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #2173FF;
    font-style: normal;
    line-height: normal;

    padding-block-start: 17px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;

    &:hover {
        background: inherit;
        text-decoration: underline;
    }
}

[data-amplify-authenticator] {
    --amplify-components-authenticator-container-width-max: 434px;
    --amplify-components-authenticator-modal-background-color: #FAFAFA;
    --amplify-components-authenticator-router-background-color: #FAFAFA;
}

[data-amplify-authenticator] [data-amplify-footer] {
    text-align: right;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding: 10px 0 0;
}

[data-amplify-authenticator] [data-amplify-router] {
    border: 0;
    box-shadow: inherit;
}
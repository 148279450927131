.chat-questions-container {
    text-align: center;
    border-radius: 8px;
    width: 100%;
    margin-top: -120px;
  }
  
  .chat-questions-title {
    color: black;
    font-size: 18px;
    font-weight: 700;
    word-wrap: break-word;
  }
  
  .chat-questions-image {
    width: 200px;
    margin-bottom: 10px;
  }
  
  .chat-questions-text {
    font-size: 16px;
  }

  .chat-powered-expert {
    color: black;
    font-size: 28px;
    font-weight: 700;
    word-wrap: break-word;
  }

  
  
  .header { 
    left: 29px;
    top: 18px;
    display: inline-flex;
    width: 80%;
  }
  
  .header-text {
    opacity: 0.81;
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 400;
    word-wrap: break-word;
    text-align: justify;
  }
  
  .suggestions {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
  }
  
  .suggestion {
    height: 70px;
    background: white;
    border-radius: 12px;
    border: 1px black solid;
    margin: 0 10px 10px 0;
    box-sizing: border-box;
    text-align: center;
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    word-wrap: break-word;
  }

  .suggestion-last {
    width: 50%;
    margin-left: 25%;
  }
  
  .single-column {
    flex: 1;
    margin-top: 20px;
  }
  
  .suggestion-text {
    width: 286px;
    height: 70px;
    left: 32px;
    top: 0px;
    position: absolute;
    text-align: center;
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    word-wrap: break-word;
  }

  .app-container {
    display: flex;
  }
  
  .column {
    flex: 1;
    margin-right: 10px;
  }
  
  .single-column {
    flex: 1;
  }
  
  .custom-box {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 12px;
    border: 1px black solid;
    text-align: center;
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-family: 'SF UI Text', sans-serif;
    font-weight: 700;
    line-height: 23px;
    word-wrap: break-word;
    margin-bottom: 10px;
  }

  .additional-text {
    width: 100%;
    opacity: 0.81;
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 400;
    word-wrap: break-word;
    margin-top: 20px;
  }
  
  .additional-text-content {
    width: 100%;
  }

  .my-component-container {
    width: 80%;
    margin-left: 10%;
    position: relative;
    border-radius: 20px;
    border: 2px rgba(27.63, 27.63, 27.63, 0.50) solid;
  }

  .my-component-container {
    width: 80%;
    margin-left: 10%;
    position: relative;
    border-radius: 20px;
    border: 2px rgba(27.63, 27.63, 27.63, 0.50) solid;
  }
  
  .my-component-container .container {
    width: 100%;
    height: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 29px;
    padding-right: 10.04px;
    border-radius: 12px;
    border: 2px rgba(27.63, 27.63, 27.63, 0.50) solid;
    justify-content: flex-end;
    align-items: center;
    gap: 395px;
    display: inline-flex;
  }
  
  .my-component-container .message {
    opacity: 0.65;
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    word-wrap: break-word;
  }
  
  .my-component-container .black-box {
    width: 34.96px;
    height: 33px;
    
  }
  
  .my-component-container .inner-black-box-1 {
    width: 34.96px;
    height: 33px;
    left: 0px;
    top: 0px;
    background: black;
    border-radius: 4.81px;
  }
  
  .my-component-container .inner-black-box-2 {
    width: 13.35px;
    height: 13.80px;
    left: 24.16px;
    top: 23.40px;
    transform: rotate(-180deg);
    transform-origin: 0 0;
    border: 2.06px white solid;
  }